import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Questionnaire.css';
import { Link } from 'react-router-dom';

const Questionnaire = () => {
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const [pathId, setPathId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [previousQuestions, setPreviousQuestions] = useState([]);

  useEffect(() => {
    axios.get('paths.json')
      .then(res => {
        setPathId(res.data.paths[0].id);
        setQuestions(res.data.paths[0].questions);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      const firstQuestion = questions.find(question => question.id === 1);
      if (firstQuestion) {
        setCurrentQuestion(firstQuestion);
      }
    }
  }, [questions]);

  const nextQuestion = (option) => {
    setSelectedOption(option);
    setPreviousQuestions([...previousQuestions, currentQuestion]);
    setCurrentQuestion(questions.find(question => question.id === currentQuestion.next_question_id));
  };

  const prevQuestion = () => {
    setCurrentQuestion(previousQuestions.pop());
  };

  const submitResponse = () => {
    console.log(selectedOption);
  };
  
  

  return (
    <div>
      {currentQuestion && currentQuestion.id ? (
        <div>
          <h2>{currentQuestion.question}</h2>
          <div>
          {currentQuestion.options && currentQuestion.options.length > 0 ? (
              currentQuestion.options.map((option, index) => (
                <div key={index}>
                  <button onClick={() => nextQuestion(option.value)}>{option.label}</button>
                </div>
              ))
            ) : (
              <div>
                <input type="text" id="response" className="textbox" />
                <button onClick={submitResponse}><Link to="/Form">Submit</Link></button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          Loading...
        </div>
      )}
      {previousQuestions.length > 0 && (
        <button onClick={prevQuestion}>Prev Question</button>
      )}
    </div>
  );  
};

export default Questionnaire;
