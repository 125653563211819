import React from 'react';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Assuming these are the user's answers from a previous form
      reason: 'Illness',
      duration: '1 to 2 weeks',
      symptom: 'Fever',
      medication: 'Ibuprofen'
    };
  }

  handlePrint = () => {
    // Assuming this function prints the form
    window.print();
  };

  handleFindDoctors = () => {
    // Assuming this function redirects to a page with doctors in the user's area
    window.location.href = '/doctors';
  };

  render() {
    return (
      <div className="user-form">
        <h1>User Information</h1>
        <ul>
          <li>Reason for visit: {this.state.reason}</li>
          <li>Symptom duration: {this.state.duration}</li>
          <li>Main symptom: {this.state.symptom}</li>
          <li>Current medication: {this.state.medication}</li>
        </ul>
        <button onClick={this.handlePrint}>Print Form</button>
        <button onClick={this.handleFindDoctors}>Find Doctors in your Area</button>
      </div>
    );
  }
}

export default UserForm;