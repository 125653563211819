import logo from './logo.svg';
import './App.css';
import TestPage from './components/TestPage';
import Questionnaire from './components/questionnaire/Questionnaire';
import UserForm from './components/output/UserForm';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      {/* using react-router-dom, I would like to have it so if the website is / or the default should show the Questionnaire component. If the website is /Form it should show UserForm component. */}
      <Router>
        <Routes>
          <Route path="/Form" element={<UserForm />} />
          <Route path="/" element={<Questionnaire />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
